.ChangeAlert-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1e1e1f50;
    z-index: 2;
  }
  
  .ChangeAlert-container {
    position: fixed;
    bottom: -10px;
    left: -10px;
    right: -10px;
    padding: 34px;
    background-color: #1e1e1f;
    color: #f2f4f5;
    z-index: 2;
    text-align: center;
    font-size: 20px;
  }
  
  .ChangeAlert-container button {
    margin-top: 16px;
    margin-bottom: 16px;
  }